import style from "./contact.css";
import { Helmet } from "react-helmet-async";
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Get in touch" />
      </Helmet>
      <div className="wrapper">
        <h3>Lucy pijnenburg</h3>
        <div className="textWrapper">
          <h3>Email</h3>
        </div>
        <div className="detailWrapper">
          <h3>lucy.pijnenburg@gmail.com</h3>
        </div>
      </div>
    </>
  );
};

export default Contact;
