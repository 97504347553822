/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import containerStyles from "./mainVideoContainer.css";
import emptyArrow from "../../images/empty-down-arrow.png";
import filledArrow from "../../images/filled-arrow-hover.png";
import video from "../../video/bigwaiting_landing.mp4";
import styled from "styled-components";

class VideoContainer extends React.Component {
  render() {
    return (
      <>
        <div
          className="container"
          onClick={() =>
            document
              .getElementById("mainBody")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          <video className="backVid" autoPlay muted loop playsinline>
            <source src={video} type="video/mp4" />
          </video>
          <div
            className="title"
            onClick={() => document.getElementById("mainBody").scrollIntoView()}
          >
            <h1 className="lucy-title">LUCY PIJNENBURG</h1>
          </div>

          <a
            className="downArrow"
            onClick={() =>
              document
                .getElementById("mainBody")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <img
              src={emptyArrow}
              width="43"
              height="30"
              className="hideOnHover"
            ></img>
            <img
              src={filledArrow}
              width="43"
              height="30"
              className="showOnHover"
            ></img>
          </a>
        </div>
      </>
    );
  }
}

export default VideoContainer;
