import { useCallback, useEffect, useState } from "react";
import { client } from "../../client";
import "./about.css";
import { Helmet } from "react-helmet-async";

const About = () => {
  const [data, setData] = useState([]);
  const [bioImage, setBioImage] = useState([]);

  const getData = useCallback(async () => {
    try {
      const response = await client.getEntries({ content_type: "bio" });
      const responseData = response.items;
      if (responseData) {
        setData(responseData[0].fields.bioText);
        setBioImage(responseData[0].fields.bioImage.fields.file.url);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About Lucy" />
      </Helmet>
      <div className="bio-wrap">
        <div className="bio new-line">{data}</div>
        <div className="profile-wrap">
          <img width="100%" src={bioImage}></img>
        </div>
      </div>
    </>
  );
};

export default About;
