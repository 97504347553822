import React, { useState, useEffect, useCallback } from "react";
import { client } from "../client";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Data = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //restructing data
  const cleanUpData = useCallback((rawData) => {
    const cleanWork = rawData.map((video) => {
      const { sys, fields } = video;
      const videoImageArray = fields.videoImage;
      const videoName = fields.videoName;
      const slug = fields.slug;
      const type = fields.type;
      const updateWork = { videoImageArray, videoName, slug, type };
      return updateWork;
    });

    setData(cleanWork);
  }, []);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    try {
      const response = await client.getEntries({ content_type: "work" });
      const responseData = response.items;
      cleanUpData(responseData);

      if (responseData) {
        cleanUpData(responseData);
      } else {
        setData([]);
      }
      setIsDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsDataLoading(false);
    }
  }, [cleanUpData]);

  //reder different number of images from each video depends on screen size
  const itemToGenerate = () => {
    if (windowWidth > 1200) {
      //generate 3 images
      return 3;
    } else if ((windowWidth < 1200) & (windowWidth > 876)) {
      return 2;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  console.log(data);

  return (
    <div className="" id="mainBody">
      {itemToGenerate() === 3
        ? data.map((video) => {
            return (
              <div className="outerwrap">
                <a href={`/${video.slug}`} className="projectGrid">
                  <LazyLoadImage
                    effect="blur"
                    className="menuVideoImg"
                    height={200}
                    width={400}
                    src={video.videoImageArray[0].fields.file.url}
                  />

                  <LazyLoadImage
                    effect="blur"
                    className="menuVideoImg"
                    height={200}
                    width={400}
                    src={video.videoImageArray[1].fields.file.url}
                  />

                  <LazyLoadImage
                    effect="blur"
                    height={200}
                    width={400}
                    className="menuVideoImg"
                    src={video.videoImageArray[2].fields.file.url}
                  />
                </a>
                <a href={`/${video.slug}`} className="linkTextContainer">
                  <span class="text--hidden">
                    {video.videoName.toUpperCase()}
                  </span>
                  <span class="text--hidden videoGenre-text">
                    {video.type === "musicvideo" ? "music video" : video.type}
                  </span>
                </a>
              </div>
            );
          })
        : itemToGenerate() === 2
        ? data.map((video) => {
            return (
              <div className="outerwrap">
                <a href={`/${video.slug}`} className="projectGrid">
                  <LazyLoadImage
                    className="menuVideoImg"
                    effect="blur"
                    height={200}
                    width={400}
                    src={video.videoImageArray[0].fields.file.url}
                  />

                  <LazyLoadImage
                    className="menuVideoImg"
                    effect="blur"
                    height={200}
                    width={400}
                    src={video.videoImageArray[1].fields.file.url}
                  />
                  <a href={`/${video.slug}`} className="linkTextContainer">
                    <span class="text--hidden">
                      {video.videoName.toUpperCase()}
                    </span>
                    <span class="text--hidden videoGenre-text">
                      {video.type === "musicvideo" ? "music video" : video.type}
                    </span>
                  </a>
                </a>
              </div>
            );
          })
        : data.map((video) => {
            return (
              <div className="outerwrap">
                <a href={`/${video.slug}`} className="projectGrid">
                  {/* <a>{video.videoName}</a> */}
                  <LazyLoadImage
                    className="menuVideoImg"
                    effect="blur"
                    height={200}
                    src={video.videoImageArray[0].fields.file.url}
                  />
                </a>
                <a href={`/${video.slug}`} className="linkTextContainer">
                  <span class="text--hidden">
                    {video.videoName.toUpperCase()}
                  </span>
                  <span class="text--hidden videoGenre-text">
                    {video.type === "musicvideo" ? "music video" : video.type}
                  </span>
                </a>
              </div>
            );
          })}
    </div>
  );
};

export default Data;
