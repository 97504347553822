import React from "react";
import Navbar from "../../components/navBar/navbar";
import Data from "../../components/data";

class IndexBody extends React.Component {
  render() {
    return (
      <>
        <Data />
      </>
    );
  }
}

export default IndexBody;
