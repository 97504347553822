import "./App.css";
import VideoContainer from "./components/mainVideoContainer/mainVideoContainer.js";
import IndexBody from "./pages/indexBody/indexBody.js";
import Navbar from "./components/navBar/navbar.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Category from "./pages/indexBody/category";
import IndividualWorkView from "./components/IndividualWorkView/index.jsx";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Selectedworks from "./pages/Selectedworks/selectedworks";

function Main() {
  let component;
  switch (window.location.pathname) {
    case "/":
      component = <IndexBody />;
      break;
    case "/arts":
      component = <Category />;
      break;
    case "/:slug":
      component = <IndividualWorkView />;
      break;
  }
  return (
    <>
      {window.location.pathname !== "/" ? null : <VideoContainer />}
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<IndexBody />} />
          <Route path="/arts" element={<Category props={"art"} />} />
          <Route
            path="/musicvideo"
            element={<Category props={"musicvideo"} />}
          ></Route>
          <Route
            path="/narrative"
            element={<Category props={"narrative"} />}
          ></Route>
          <Route
            path="/documentary"
            element={<Category props={"documentary"} />}
          ></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />} />
          <Route path="/selected-works" element={<Selectedworks />} />
          <Route path="/:slug" element={<IndividualWorkView />} />
        </Routes>
      </Router>
    </>
  );
}

export default Main;
