import React, { useState, useEffect, useCallback } from "react";
import { client } from "../../client";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Selectedworks = () => {
  const [data, setData] = useState([]);
  const param = useParams();
  const [urls, setUrls] = useState([]);

  const cleanUpData = useCallback((rawData) => {
    const { fields } = rawData;
    const videoImages = fields.videoImage.map((image) => {
      return image.fields.file.url;
    });
    setUrls(videoImages);
    setData(fields);
    // console.log(urls)
    // console.log(data)
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: "work",
        "fields.slug": param.slug,
      });
      const responseData = response.items[0];

      if (responseData) {
        cleanUpData(responseData);
      }
    } catch (error) {
      console.log(error);
    }
  }, [cleanUpData]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Helmet>
        <title>Selected work</title>
        <meta
          name="description"
          content="A short reel to showcase Lucy's work"
        />
      </Helmet>
      <div className="popup">
        <div className="video-wrapper">
          <iframe
            loading="lazy"
            src="https://player.vimeo.com/video/717394973?h=5552c3486a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen"
          />
        </div>
      </div>
    </>
  );
};

export default Selectedworks;
