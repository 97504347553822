import React, { useEffect, useRef } from "react";
import { render } from "react-dom";
import smallLogo from "../../images/logo_small.png";
import "./navbar.css";
import { FaInstagram } from "react-icons/fa";
import { FaVimeoV } from "react-icons/fa";
import { Switch, Route } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {
  let [menuOpen, setMenuOpen] = useState(false);
  const toggleButton = document.querySelector(".toggle-button");
  const listElement = useRef();

  const toggleLinksToActive = () => {
    if (!menuOpen) {
      document.querySelector(".navbar-links").classList.toggle("active");
      listElement.current.classList.add("open");
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
      listElement.current.classList.remove("open");
      document.querySelector(".navbar-links").classList.remove("active");
    }
  };

  return (
    <>
      <div className="navbar">
        <a href="/">
          <img className="logo" src={smallLogo}></img>
        </a>
        <button
          className="toggle-button"
          ref={listElement}
          onClick={toggleLinksToActive}
        >
          <div className="bar"></div>
        </button>

        <div className="navbar-links">
          <a className="link link-color" href="/selected-works">
            selected work
          </a>
          <a className="link link-color" href="/narrative">
            narrative
          </a>
          <a className="link link-color" href="/documentary">
            documentary
          </a>
          <a className="link link-color" href="/musicvideo">
            music video
          </a>
          <a className="link link-color" href="/about">
            about
          </a>
          <a className="link link-color" href="/contact">
            contact
          </a>
          <a
            href="https://www.instagram.com/lucy.pij/"
            className=" link-color icon"
          >
            {" "}
            <FaInstagram />
          </a>

          <a href="https://vimeo.com/user67929477">
            {" "}
            <FaVimeoV className="link-color icon" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
