import "./style.css";
import React, { useState, useEffect, useCallback } from "react";
import { client } from "../../client";
import { useParams } from "react-router-dom";


const IndividualWorkView = () => {
    const [data, setData] = useState([]);
    const param = useParams();
    const [urls, setUrls] = useState([]);

    const cleanUpData = useCallback((rawData) =>{
        const {fields} = rawData;
        const videoImages = fields.videoImage.map((image)=>{
            return image.fields.file.url
        }) 
        setUrls(videoImages);
        setData(fields);
        // console.log(urls)
        // console.log(data)
    }, []);

    const getData = useCallback(async () =>{
        try{
            const response = await client.getEntries({
                content_type: "work",
                "fields.slug": param.slug
            })
            const responseData = response.items[0];
            
            if(responseData){
                cleanUpData(responseData);
            }
        }
        catch(error){
            console.log(error);
        }
    },[cleanUpData])

    useEffect(()=>{
        getData();
    },[getData]);


    return (
    <>
  
    <div className="popup">
        {data.videoUrl !== 'null'?  <div className="video-wrapper">
            <iframe loading="lazy" src={data.videoUrl} frameBorder="0"allow="autoplay; fullscreen"/>
        </div> : null }
       
        <div className="video-credits">
            {data.director === data.producer? <p><strong>DIRECTOR | PRODUCER</strong> {data.producer}</p> : <><p><strong>DIRECTOR</strong> {data.director}</p><p><strong>PRODUCER</strong> {data.producer}</p></>}
            {data.producer2 && !data.cinematographer && <p><strong>PRODUCER</strong> {data.producer2}</p>}
            {data.cinematographer && <p><strong>Producer | Cinematographer</strong> Lucy Pijnenburg</p>}
        </div>

        {data.videoUrl === 'null' && 
        <div className="projectGrid">
            {urls.map((url) =>{
                return (
                    <img className="stills" src={url}></img>
                )
            })}
        </div> }
    </div>
    </>)
}

export default IndividualWorkView;